@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.dark {
  background-color: #1a202c !important;
  color: #fff !important;
}

* {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

/* a:hover {
  color: #500a6e;
  text-decoration: dotted;
} */

/* .ant-btn-primary {
  background: #ffffff !important;
  color: rgb(44, 41, 206);
} */

.border-none {
  border: 0 !important;
}

.custom-datepicker-dropdown .ant-picker-dropdown {
  top: auto !important;
  bottom: -100% !important;
}
